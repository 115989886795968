import { noop } from '@vueuse/core';
import { Mesh } from 'three';
import { computed, defineComponent, inject, onMounted, provide, reactive, watch } from 'vue';

import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
import { useThreeObject, useViewportResize } from '@resn/gozer-vue';

import { LAYER_FG } from '~/core/constants';

export const useBounds = (cb = noop) => {
    let bounds = inject('bounds', null);

    if (!bounds) {
        const viewport = useViewportResize();
        bounds = reactive({
            x: 0,
            y: 0,
            width: computed(() => viewport.width),
            height: computed(() => viewport.height),
        });
    }

    watch(bounds, cb);

    onMounted(() => cb(bounds));

    return bounds;
};

export const Bounds = defineComponent({
    props: {
        data: Object,
    },

    setup() {
        const bounds = reactive({ x: 0, y: 0, width: 1, height: 1 });

        const { object } = useThreeObject(null, { name: 'Bounds' });

        const mesh = new Mesh(simplePlaneGeometry, simpleMaterial('red', true));
        mesh.layers.set(LAYER_FG);
        mesh.material.depthTest = false;
        mesh.material.opacity = 0.1;
        mesh.renderOrder = 10;

        // object.add(mesh);

        provide('bounds', bounds);

        useViewportResize(({ width, height }) => {
            bounds.width = width;
            bounds.height = height;
            bounds.x = 0;
            bounds.y = height / 2 - bounds.height / 2;

            // ― debug
            /* console.log('🚀 ~ useViewportResize ~ bounds:', bounds);
            object.position.set(bounds.x, bounds.y, 0);
            mesh.scale.set(bounds.width, bounds.height, 1); */
        }, true);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }
        return null;
    },
});
